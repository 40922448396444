import React from "react"
import RegularInterview from "./pages/RegularInterview"
import RadioInterview from "./pages/RadioInterview"

import FillInTheBlanks from "./pages/special-screens/FillInTheBlanks"
import SplashPage from "./pages/special-screens/SplashPage"
import MainMenuPage from "./pages/special-screens/MainMenuPage"
import SpecialMainMenuPage from "./pages/custom-screens/SpecialMainMenuPage"
import CardPage from "./pages/special-screens/CardPage"
import ResultSetPage from "./pages/custom-screens/ResultSetPage"
import ResultSetLegal from "./pages/custom-screens/ResultSetLegal"
import InterviewWithUrgentHelp from "./pages/custom-screens/InterviewWithUrgentHelp"
import InterviewARIEvaluation from "./pages/custom-screens/InterviewARIEvaluation"
import InterviewFieldsWithCancel from "./pages/custom-screens/InterviewFieldsWithCancel"

const Interview = (props) => {

  // console.log('screenType = ', props.screenType)

  switch(props.screenType) {
    // Special pages
    case "splash-screen":
      return (
      <SplashPage
        isLoadedInApp={props.isLoadedInApp}
        showButtonSpinner={props.showButtonSpinner}
        interview={props.interview}
        sessionID={props.sessionID}
        handleClick={props.handleClick}
        goPrevQuestion = {props.goPrevQuestion}
        restartInterview = {props.restartInterview} />
      )
      // eslint-disable-next-line
      break
    case "main-menu":
      return (
        <SpecialMainMenuPage
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break;
    case "menu-by-two":
      return (
        <MainMenuPage
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview}
          menuType="menu-by-two" />
        )
        // eslint-disable-next-line
        break
    case "menu-by-two-long":
      return (
        <MainMenuPage
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview}
          menuType="menu-by-two-long" />
        )
        // eslint-disable-next-line
        break
    case "card-screen":
      return (
        <CardPage
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break
    case "fill-in-the-blanks":
      return (
        <FillInTheBlanks
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break
    case "resultset":
      return (
        <ResultSetPage
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break
    case "resultset-legal":
      return (
        <ResultSetLegal
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break

    case "radio-buttons-choice":
      return (
        <RadioInterview
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
        )
        // eslint-disable-next-line
        break

    // With urgent help
    case "with-urgent-help":
      return (
        <InterviewWithUrgentHelp
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
      )
      // eslint-disable-next-line
      break

    // ARI evaluation
    case "show_ari_text":
      return (
        <InterviewARIEvaluation
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
      )
      // eslint-disable-next-line
      break

    // Submit with cancel button pressed
    case "login_screen":
      return (
        <InterviewFieldsWithCancel
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
      )
      // eslint-disable-next-line
      break

    // Regular page
    case "regular":
      return (
        <RegularInterview
          isLoadedInApp={props.isLoadedInApp}
          showButtonSpinner={props.showButtonSpinner}
          interview={props.interview}
          sessionID={props.sessionID}
          handleClick={props.handleClick}
          goPrevQuestion = {props.goPrevQuestion}
          restartInterview = {props.restartInterview} />
      )
      // eslint-disable-next-line
      break
    default:
  }
}

export default Interview;
