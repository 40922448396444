const translationsEn = {
  // ***************
  // * Framework *
  // ***************
  "exit-help-modal-txt": "Got it, thanks.",
  "exit-generic-modal-txt": "OK",
  "app-exit-message": "End of session",
  "app-exit-thankyou": "<p class='mb-2'>You can use the pathway again if you need to find information for another client.</p>",
  "questions": "Navigation",
  "prev-question-txt": "Previous screen",
  "prev-question-explain": "Go back to the previous screen.",
  "quick-links": "Quick Links",
  "ql-mh-find": "Find mental health services",
  "ql-mh-find-explain": "Quickly find free or low-cost mental health services.",
  "ql-legal-find": "Find legal services",
  "ql-legal-find-explain": "Quickly find free or affordable legal services.",
  "ql-interpreter-find": "Find interpreting services",
  "ql-interpreter-find-explain": "Quickly find out when and how to ask for free interpreting.",
  "find-out-more-txt": "Find out more",
  "reload-txt": "Restart",
  "reload-explain": "Go through the guided pathway again from the start.",
  "main-menu-txt": "Main Menu",
  "main-menu-explain": "Go back to the main menu.",
  "da-main-menu": "Main Menu",
  "web-site-txt": "Go to Legal Tech Helper",
  "about": "About",
  "about-app": "About the Enhanced Automated Readability Check",
  "about-app-mobile": "About the web app",
  "input-box": "Input box",
  "input-field": "Input field",
  "password-field": "Password field",
  "more-info": "More information",
  "about-app-desc": "Find out more about this web application.",
  "privacy-txt": "Privacy Collection Notice",
  "privacy-desc": "Get a better understanding of how we safeguard your privacy.",
  "website-terms": "Terms of Use",
  "website-terms-desc": "Read about the terms that apply when using this tool.",
  "urgent-help": "Urgent Help",
  "restart-confirm-header":"Restart",
  "restart-confirm-text":"Are you sure you want to go through the guided pathway from the start?",
  "yes-answer": "Yes",
  "no-answer": "No",
  "OK-answer": "OK",
  "quick-exit": "Quick exit",
  "quick-exit-short": "Exit",
  "contact-info-txt": "Contact us",
  "contact-info-explain": "Contact us at the Peninsula Community Legal Centre",
  "contact-info-header": "Contact us",
  "help-info-txt": "Help",
  "help-info-explain": "Get help understanding readability metrics.",
  "continue-txt": "Continue",
  // eslint-disable-next-line no-useless-escape
  "cancel-txt": "I don\'t have a code",
  "previous-label": "Previous",
  "next-label": "Next",
  "phone": "Phone",
  "email": "Email",
  "website": "Website",
  "website-link": "Website",
  "address": "Address",
  "service-type": "Service type",
  "walkins-badge-label": "Walk-ins",
  "mht-label": "Mental Health Tribunal",
  "treatment-orders-label": "Treatment Orders",
  "family-law-label": "Family Law",
  "criminal-law-label": "Criminal Law",
  "immigration-law-label": "Immigration and asylum",
  "tenancy-law-label": "Tenancy",
  "guardianship_adm-law-label": "Guardianship and Administration",
  "ndis-label": "NDIS",
  "debts-label": "Debts",
  "vietnamese-label": "Services in Vietnamese and English",
  "walkins-available": "No appointment needed<span class=\"hidden md:inline\">, you can walk in</span>",
  "telehealth-badge-label": "Telehealth",
  "telehealth": "Phone or video<span class=\"hidden md:inline\"> (Telehealth)</span> appointments<span class=\"hidden md:inline\"> available</span>",

  // ***************
  // * Application *
  // ***************
  "alt-message-for-brand": "Legal Tech Helper",
  "short-message-for-brand": "Legal Tech",
  "short-message-for-brand-02": "Helper",
  "app-name-top-menu-item": "Back to application",
  "menu-header": "Menu",
  "go-back-step": "Go Back a Step",
  "go-back-step-explain": "Go back a step in the Family Violence Provisions process.",

  // **************************
  // * Application Long Texts *
  // **************************
  // Urgent Help
  "urgent-help-text": `
    <p class="urgent-help"">
      Help available 24 hours x 7 days a week:
    </p>
    <p class="urgent-help">
      <b>Emergency, police and ambulance</b><br/>
      Call: <a href="tel:000">000</a><br/>
    </p>
    <p class="urgent-help">
      <b>1800 RESPECT</b> - Family violence and sexual assault help line<br/>
      Call: <a href="tel:1800737732">1800 737 732</a><br/>
      <a class="external-link" href="https://1800respect.org.au" target="_blank">
        1800respect.org.au
      </a>
    </p>
    <p class="urgent-help">
      <b>Safe Steps Family Violence Response Centre</b><br/>
      Call: <a href="tel:1800015088">1800 015 088</a><br/>
      <a class="external-link" href="https://safesteps.org.au" target="_blank">
        safesteps.org.au
      </a>
    </p>
    <p class="urgent-help pb-0">
      If you do not speak English, you can call these services and ask for an interpreter.
      The interpreter is free.
    </p>
    <p class="urgent-help pb-0">
      Or you can call the free <b>Translation and Interpreting Service (TIS)</b>
      on <a href="tel:131450">131&nbsp;450</a>:
      <ul class="text-brandSecondary ml-5" style="list-style-type:square;">
      <li><span class="text-brandTextDark">say your language</span></li>
      <li><span class="text-brandTextDark">ask to contact the service you need.</span></li>
      </ul>
    </p>`,

    // PCLC Contact Text
    // "pclc-contact-text": `
    // <p class="mt-4 xl:mt-8 mb-0 md:mb-4">
    //   Please contact us at the <b>Peninsula Community Legal Centre (PCLC)</b> by:-
    // </p>
    // <div class="table w-full">
    //   <div class="table-row-group">
    //     <div class="table-row md:pb-2 md:leading-7">
    //       <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Phone:</div>
    //       <div class="block md:table-cell md:w-fit">
    //         <a href="tel:0397833600" class="phone-link">(03) 9783 3600</a>
    //       </div>
    //     </div>
    //     <div class="table-row md:pb-2 md:leading-7">
    //       <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Toll-free:</div>
    //       <div class="block md:table-cell md:w-fit">
    //         <a href="tel:1800064784" class="phone-link">1800 064 784</a>
    //       </div>
    //     </div>
    //     <div class="table-row md:pb-2 md:leading-7">
    //       <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Email:</div>
    //       <div class="block md:table-cell md:w-fit">
    //         <a href="mailto:migration@pclc.org.au" class="email-link" target="_blank">migration@pclc.org.au</a>
    //       </div>
    //     </div>
    //     <div class="table-row md:pb-2 md:leading-7">
    //       <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Contact form:</div>
    //       <div class="block md:table-cell md:w-fit">
    //         <a href="https://legaltechhelper.com.au/contact-us/" target="_blank"
    //         class="form-link">pclc.org.au/contact-us</a>
    //       </div>
    //     </div>
    //     <div class="table-row md:pb-2 md:leading-7">
    //       <div class="block mt-2 md:table-cell md:w-5/12 lg:w-4/12">Website:</div>
    //       <div class="block md:table-cell md:w-fit">
    //         <a href="https://legaltechhelper.com.au/" class="web-link" target="_blank">pclc.org.au</a>
    //       </div>
    //     </div>
    //
    //   </div>
    // </div>`,

  // *******************************
  // * Mental Health Service Types *
  // *******************************
  // Mental Health and Wellbeing Local service
  "mh-and-wellbeing-local": `
    <p class="mb-2">
      <b>Mental Health and Wellbeing Local</b> are <b>free</b> services to help people
      with mental health challenges. They give free treatment, care and support.
    </p>
    <p class="mb-2">
      You do not need a doctor’s referral, you do not
      need a mental health care plan or a Medicare card to use the service.
    </p>
    <p class="mb-0">
      Family members, carers, friends or supporters can also get <b>free</b> help for
      themselves.
    </p>`,

    // Primary Health Network
    "phn": `
      <p class="mb-2">
        <b>Primary Health Networks (PHNs)</b> help people find mental health services
        close to them. They link different services, like hospitals, doctors,
        aged care facilities, and make sure people are getting the care they need.
        They also provide information and resources.
      </p>
      <p class="mb-0">
        There are 6 Primary Health Networks in Victoria each covering a different
        region.
      </p>`,

    // Mental Health and Wellbeing Hub
    "mh-and-wellbeing-hub": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Hubs</b> are free services that help
        people with mental health challenges. You don’t need a Medicare card
        or doctor referral. You can call to make an appointment or just walk in.
      </p>
      <p class="mb-0">
        Mental Health and Wellbeing Hubs are being replaced by Mental Health and
        Wellbeing Local services, but they continue to operate in areas where
        Local services are not available yet.
      </p>`,

    // headspace
    "headspace": `
      <p class="mb-2">
        <b>headspace</b> helps young people aged 12-25 with their mental health.
        They offer free online and phone help, as well as free or low-cost
        services.
      </p>
      <p class="mb-0">
        They also help family members and carers of people with mental health challenges.
      </p>`,

    // Family and Carer-Led Centre and Mental Health and Wellbeing Connect Centre
    "connect-centre": `
      <p class="mb-2">
        <b>Mental Health and Wellbeing Connect centres</b> are places where family
        members, carers and supporters of people with mental health challenges can go
        for support.
      </p>
      <p class="mb-0">
        They are free and anyone can visit and are run by people who have lived
        experience. They can give information, help find resources, and access
        to hardship money.
      </p>`,

    // Community Mental Health Clinic
    "community-mh-clinic": `
      <p class="mb-2">
        Saltwater Clinic is an adult community mental health clinic,
        providing mental health community care for people living in the
        Maribyrnong and Hobsons Bay municipalities. Saltwater Clinic is an
        appointment-only service.
      </p>
      <p class="mb-0">
        Referrals are through the Mercy Mental Health Triage.
      </p>`,

  // *********
  // * Pages *
  // *********
  // Privacy Policy
  "privacy-heading": "Privacy Collection Notice",
  "privacy-text": `
  <div class="static-conent">
    <p class="my-2">
      Legal Tech Helper is committed to providing quality services to you and this policy outlines our ongoing obligations to you in
      respect of how we manage your Personal Information.
    </p>
    <p class="my-2">
      We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988 (Cth) (the Privacy Act). The APPs
      govern the way in which we collect, use, disclose, store, and secure your Personal Information.
    </p>
    <p class="mt-2 mb-4">
      A copy of the Australian Privacy Principles may be obtained from the website of The Office of the Australian Information Commissioner
      at <a class="regularLink" href="https://www.oaic.gov.au/privacy/australian-privacy-principles/read-the-australian-privacy-principles"
      target="_blank">oaic.gov.au/privacy/australian-privacy-principles/read-the-australian-privacy-principles</a>.
    </p>
    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-semibold">
      Purpose
    </h2>
    <p class="mt-2 mb-4">
      Legal Tech Helper may use aggregated information it collects, in the following ways:
    </p>
    <ul class="text-brandTextDark">
      <li class="text-black mb-2" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>(a)</b></span> to expand web application functionality in the future or to apply funding or resources; and/ or</li>
      <li class="text-black mb-5" style="list-style-type: none;"><span class="mr-1 md:mr-4"><b>(b)</b></span> to monitor website traffic for reporting purposes.</li>
    </ul>
    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-semibold">
      Disclosure
    </h2>
    <p class="mb-4 mt-2">
      Legal Tech Helper recognises the importance of keeping personal information confidential and ensures that all information received from service users will not be disclosed to third
      parties without consent, unless authorised by law.
    </p>
    <p class="mb-4 mt-2">
      Legal Tech Helper ensures that no personal information will be sold to third parties or used for marketing purposes.</li>
    </ul>
    <h2 class="mt-2 font-sans text-lg text-brandSecondary font-semibold">
      Contact
    </h2>
    <p class="mb-4 mt-2">
      If you have any questions about this notice or how we handle your personal information more generally, contact Legal Tech Helper:
      <a class="regularLink" href="mailto:&#105;&#110;&#102;&#111;&#064;&#108;&#101;&#103;&#097;&#108;&#116;&#101;&#099;&#104;&#104;&#101;&#108;&#112;&#101;&#114;&#046;&#099;&#111;&#109;&#046;&#097;&#117;">&#105;&#110;&#102;&#111;&#064;&#108;&#101;&#103;&#097;&#108;&#116;&#101;&#099;&#104;&#104;&#101;&#108;&#112;&#101;&#114;&#046;&#099;&#111;&#109;&#046;&#097;&#117;</a>.
    </p>
  </div>`,

  // About Page
  "about-heading": "About the Enhanced Automated Readability Check",
  "about-text": `
    <div class="static-conent">
    <p class="my-2">
      Thank you for using Legal Tech Helper’s
      <span class="font-semibold"><i>Enhanced Automated Readability Check</i></span> web application.
      We built it for you to be able to score the readability of text according to widely-used metrics.
    </p>
    <p>
      If you would like to give us some feedback please email us at
      <a class="regularLink" href="mailto:&#105;&#110;&#102;&#111;&#064;&#108;&#101;&#103;&#097;&#108;&#116;&#101;&#099;&#104;&#104;&#101;&#108;&#112;&#101;&#114;&#046;&#099;&#111;&#109;&#046;&#097;&#117;">&#105;&#110;&#102;&#111;&#064;&#108;&#101;&#103;&#097;&#108;&#116;&#101;&#099;&#104;&#104;&#101;&#108;&#112;&#101;&#114;&#046;&#099;&#111;&#109;&#046;&#097;&#117;</a>
    </p>
    <h2 class="mt-4 mb-2 font-sans text-lg text-brandSecondary font-semibold">
      Credits
    </h2>
    <p class="mb-4">
      Designed and built by
      <a class="regularLink" target="_blank"
      href="https://legaltechhelper.com.au">Legal Tech Helper</a>.
    </p>
    <h2 class="mb-2 font-sans text-lg text-brandSecondary font-semibold">
      Version
    </h2>
    <p class="mb-4" style="margin-top: 0.25rem;">
      Enhanced Automated Readability Check - <span class="font-semibold">version 0.0.3alpha</span>
    </p>`,

  // Website Terms Page
  "terms-heading": "Terms of Use",
  "terms-text": `
    <div class="static-conent">
      <p class="mb-4 mt-2">
        Placeholder text.
      </p>
    </div>`,

    // Help Page
    "help-heading": "Help for Enhanced Automated Readability Check",
    "help-text": `
      <div class="static-conent">
      <p class="my-2">
        The <span class="font-semibold">Enhanced Automated Readability Check (EARC)</span> tool helps to score the readability
        of text.
      </p>
      <p class="mt-2">
        The tool uses a number of readability metrics to arrive at a “Readability Consensus”
        that is essentially a score that corresponds to the grade level in the US school system
        that is needed to comprehend the text. This is, in turn, used to determine if the text meets the
        Web Content Accessibility Guidelines (WCAG) AAA Reading Level.
      </p>
      <p class="mt-2">
        Note that historically, the readability metrics have been scored at a grade level in a US school system.
        WCAG Reading Levels, on the other hand, use the International Standard Classification of Education (ISCED)
        levels established by the International Standard Classification of Education (ISCE) which is part of the United Nations
        Educational, Scientific and Cultural Organisation (UNESCO).
        They are intended as an international classification system that can be used across different countries, languages
        and education systems.
      </p>
      <p style="margin-top:0;">
        See
        <a class="external-link" href="https://www.w3.org/WAI/WCAG22/Understanding/reading-level.html" target="_blank">
          <span class="md:hidden">https://www.w3.org/WAI/WCAG22/</span>
          <span class="md:hidden">Understanding/reading-level.html</span>
          <span class="hidden md:inline">https://www.w3.org/WAI/WCAG22/Understanding/reading-level.html</span>
        </a>.
      </p>

      <!-- Readability metrics -->
      <h2 class="mt-4 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        Readability metrics
      </h2>
      <p>
        The tool uses a number of readability metrics such as:-
      </p>
      <ul style="list-style-type: none!important; padding: 0!important; margin: 0!important;">
        <li class="mt-1 mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests#Flesch_reading_ease" target="_blank">
            Flesch Reading Ease
          </a><span class="text-brandCharcoal">,</span>
        </li>
        <li class="mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests#Flesch%E2%80%93Kincaid_grade_level" target="_blank">
            Flesch-Kincaid Grade Level
          </a><span class="text-brandCharcoal">,</span>
        </li>
        <li class="mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Gunning_fog_index" target="_blank">
            Gunning FOG Scale
          </a><span class="text-brandCharcoal">,</span>
        </li>
        <li class="mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/SMOG" target="_blank">
            SMOG Index
          </a><span class="text-brandCharcoal">,</span>
        </li>
        <li class="mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Coleman%E2%80%93Liau_index" target="_blank">
            Coleman-Liau Index
          </a><span class="text-brandCharcoal">,</span>
        </li>
        <li class="mb-2">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Linsear_Write" target="_blank">
            Linsear Write Formula
          </a><span class="text-brandCharcoal"> and</span>
        </li>
        <li class="mb-0">
          <a class="external-link" href="https://en.wikipedia.org/wiki/Dale%E2%80%93Chall_readability_formula" target="_blank">
            Dale-Chall Readability Score
          </a><span class="text-brandCharcoal">.</span>
        </li>
      </ul>

      <!-- Why are the readability metrics different? -->
      <h2 class="mt-6 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        Why are the readability metrics different from Microsoft Word?
      </h2>
      <p class="my-2">
        The differences in Flesch-Kincaid readability scores between EARC (which uses a Python library called textstat)
        and Microsoft Word's implementation can be attributed to variations
        in algorithm implementation, text preprocessing, syllable counting methods and sentence boundary detection.
      </p>
      <p class="mb-0">
        These factors can lead to inconsistencies of the final scores produced by each tool.
      </p>

      <!-- WCAG AAA Reading Level -->
      <h2 class="mt-6 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        Meeting the WCAG AAA Reading Level
      </h2>
      <p class="mt-2 mb-1">
        Section 3.1.5 of WCAG 2.2 specifies the success criteria for meeting AAA Reading Level as:
      </p>
      <p class="ml-4 mb-1" style="margin-top: 0">
        "[when] a version [of the text] that does not require reading ability more advanced than the lower secondary education level, is available"
      </p>
      <p style="margin-top:0;">
        <span class="md:ml-4">
        <a class="external-link" href="https://www.w3.org/TR/WCAG22/#reading-level" target="_blank">
          https://www.w3.org/TR/WCAG22/#reading-level</a>.
        </span>
      </p>
      <p class="mt-2 mb-1">
        Note that proper nouns are exempt from readability assessment:
      </p>
      <p class="ml-4 mb-1" style="margin-top: 0">
        "Because people's names, the names of cities or other proper names cannot be changed to shorter names with fewer syllables,
        and because doing so or just referring to everyone by pronouns can make sentences harder to understand, the success criterion
        specifies that proper names can be ignored or removed from the text before assessing whether it meets the reading ability requirement.
        Titles refer to the name of documents, books, movies, etc. Titles are removed or ignored for the analysis because changing the words
        in titles might make the titles easier to read but would make it impossible to understand the item to which the title refers.
        This would make it harder to read and understand the content. (e.g., a book, academic paper, article, etc.). Therefore, titles are
        also exempted specifically."
      </p>
      <p style="margin-top:0;">
        <span class="md:ml-4">
          <a class="external-link" href="https://www.w3.org/WAI/WCAG21/Understanding/reading-level.html" target="_blank">
            <span class="md:hidden">https://www.w3.org/WAI/WCAG22/</span>
            <span class="md:hidden">Understanding/reading-level.html</span>
            <span class="hidden md:inline">https://www.w3.org/WAI/WCAG22/Understanding/reading-level.html</span>
          </a>
        </span>
      </p>
      <p class="mt-2 mb-1">
        Where text is higher than the lower secondary education level, it can be ameliorated in a number of ways:
      </p>
      <p class="ml-4 mt-0 mb-1" style="margin-top: 0">
        "G86: Providing a text summary that can be understood by people with lower secondary education level reading ability
      </p>
      <p class="ml-4 mt-0 mb-1" style="margin-top: 0">
        G103: Providing visual illustrations, pictures, and symbols to help explain ideas, events, and processes
      </p>
      <p class="ml-4 mt-0 mb-1" style="margin-top: 0">
        G79: Providing a spoken version of the text
      </p>
      <p class="ml-4 mt-0 mb-1" style="margin-top: 0">
        G153: Making the text easier to read
      </p>
      <p class="ml-4  mt-0 mb-1" style="margin-top: 0">
        G160: Providing sign language versions of information, ideas, and processes that must be understood in order to use the content"
      </p>
      <p style="margin-top:0;">
        <span class="md:ml-4">
        <a class="external-link" href="https://www.w3.org/WAI/WCAG22/quickref/?showtechniques=315#reading-level" target="_blank">
          https://www.w3.org/WAI/WCAG22/quickref/?showtechniques=315#reading-level</a>.
        </span>
      </p>

      <!-- Colour keys -->
      <h2 class="mt-6 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        Colour keys used in the Enhanced Automated Readability Check web application
      </h2>
      <table>
      <tr>
        <td>
          <span class="text-2xl text-green-200 align-baseline mr-2" style="line-height:1;">&#9632;</span>
        </td>
        <td>
          <span class="text-black" style="font-weight: 500;">
            Passes WCAG 2.2 AAA Reading Level
          </span>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <div class="text-black mb-2">
            ISCED levels: Early childhood, Primary and Lower secondary education levels.
          </div>
        </td>
      </tr>

      <tr>
        <td style="vertical-align: top;">
          <span class="text-2xl text-cyan-200 align-baseline mr-2" style="line-height:1;">&#9632;</span>
        </td>
        <td style="vertical-align: top;">
          <span class="text-black" style="font-weight: 500;">
            Does not pass WCAG 2.2 AAA Reading Level
          </span>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <div class="text-black mb-2">
            Text will need to be supplemented with explanations and/ or a summary to be usable by the general public.<br/>
            ISCED level: Upper secondary education level.
          </div>
        </td>
      </tr>

      <tr>
        <td>
          <span class="text-2xl text-rose-200 align-baseline mr-2" style="line-height:1;">&#9632;</span>
        </td>
        <td>
          <span class="text-black" style="font-weight: 500;">
            Too complex for the general public
          </span>
        </td>
      </tr>
      <tr>
        <td>
        </td>
        <td>
          <div class="text-black mb-0">
            This text can be used for professionals and specialists.<br/>
            ISCED levels: Bachelors degree (or equivalent tertiary education) level and above.
          </div>
        </td>
      </tr>
      </table>

      <!-- Reading Levels -->
      <h2 class="mt-4 mb-4 font-sans text-lg text-brandSecondary font-semibold">
        Reading levels in more detail
      </h2>
      <div class="table w-full">
        <div class="table-row-group">

          <div class="hidden md:table-row bg-slate-100">
            <div class="px-4 py-2 block md:table-cell md:w-3/12" style="border-top-left-radius: 6px;">
              <b>Readability Consensus</b>
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              <b>Age</b>
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit" style="border-top-right-radius: 6px;">
              <b>Description and ISCED Level</b>
            </div>
          </div>

          <div class="table-row  md:hidden">
            <div class="px-4 py-2 bg-green-100 block md:table-cell md:w-3/12" style="border-top-left-radius: 6px; border-top-right-radius: 6px;">Early childhood education level</div>
            <div class="px-4 py-2 bg-green-100 block md:table-cell md:w-3/12">
              Age 4 – 5
            </div>
            <div class="px-4 py-2 bg-green-100 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Pre-K in the US education system</span></li>
              <li><span class="text-black">Reception year in the UK</span></li>
              <li><span class="text-black">Preschool in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 0 - Early childhood education
              </p>
            </div>
          </div>
          <div class="md:table-row bg-green-100 hidden">
            <div class="px-4 py-2 block md:table-cell md:w-3/12">Early childhood education level</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 4 – 5
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Pre-K in the US education system</span></li>
              <li><span class="text-black">Reception year in the UK</span></li>
              <li><span class="text-black">Preschool in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 0 - Early childhood education
              </p>
            </div>
          </div>
          <div class="table-row bg-green-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">Early childhood education level</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 5 - 6
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Kindergarten in a US K-12 education system</span></li>
              <li><span class="text-black">Year 1 in UK education system</span]></li>
              <li><span class="text-black">Prep or Foundation year in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 0 - Early childhood education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">1st grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 6 - 7
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 1 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 2 in UK education system</span]></li>
              <li><span class="text-black">Grade 1 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">2nd grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 7 - 8
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 2 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 3 in UK education system</span]></li>
              <li><span class="text-black">Grade 2 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">3rd grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 8 - 9
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 3 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 4 in UK education system</span]></li>
              <li><span class="text-black">Grade 3 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">4th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 9 - 10
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 4 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 5 in UK education system</span]></li>
              <li><span class="text-black">Grade 4 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">5th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 10 - 11
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 5 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 6 in UK education system</span]></li>
              <li><span class="text-black">Grade 5 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">6th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 11 - 12
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 6 in elementary school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 7 (Form 1) in UK education system</span]></li>
              <li><span class="text-black">Grade 6 in primary school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 1 - Primary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">7th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 12 - 13
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 7 in middle school (or junior high school) in a US K-12 education system</span></li>
              <li><span class="text-black">Year 8 (Form 2) in UK education system</span]></li>
              <li><span class="text-black">Year 7 in high school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 2 - Lower secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">8th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 13 - 14
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 8 in middle school (or junior high school) in a US K-12 education system</span></li>
              <li><span class="text-black">Year 9 (Form 3) in UK education system</span]></li>
              <li><span class="text-black">Year 8 in high school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 2 - Lower secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-green-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">9th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 14 - 15
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 9 in senior high school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 10 (Form 4) in UK education system</span]></li>
              <li><span class="text-black">Year 9 in high school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 2 - Lower secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-cyan-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">10th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 15 - 16
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 10 in senior high school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 11 (Form 5) in UK education system</span]></li>
              <li><span class="text-black">Year 10 in high school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 3 - Upper secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-cyan-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">11th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-3/12">
              Age 16 - 17
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 11 in senior high school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 12 (Lower Form 6) in UK education system</span]></li>
              <li><span class="text-black">Year 11 in high school in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 3 - Upper secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-cyan-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">12th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 17 - 18
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">Grade 12 in senior high school in a US K-12 education system</span></li>
              <li><span class="text-black">Year 13 (Upper Form 6) in UK education system</span]></li>
              <li><span class="text-black">Year 12 in high school or Certificate III, which is considered equivalent to Year 12 as part of Vocational Education and Training (VET) in Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 3 - Upper secondary education
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">13th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 18 - 19
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">College Freshman year in the US</span></li>
              <li><span class="text-black">1st year in a tertiary institution in the UK or Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 6 - Bachelors degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">14th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 19 - 20
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">College Sophomore year in the US</span></li>
              <li><span class="text-black">2nd year in a tertiary institution in the UK or Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 6 - Bachelors degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">15th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 20 - 21
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">College Junior year in the US</span></li>
              <li><span class="text-black">3rd year in a tertiary institution in the UK or Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 6 - Bachelors degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">16th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 21 - 22
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">College Senior year in the US</span></li>
              <li><span class="text-black">Honours year in a tertiary institution in the UK or Australia.</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 6 - Bachelors degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">17th grade</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Age 22 - 23
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              This would be equivalent to:
              <ul>
              <li><span class="text-black">College Senior year in the US</span></li>
              <li><span class="text-black">Honours year in a tertiary institution in the UK or Australia</span></li>
              </ul>
              <p class="m-0 p-0">
                ISCED level 6 - Bachelors degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

          <div class="table-row bg-rose-50">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12">Masters degree or equivalent level</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
               &ndash;
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
              Masters degree
              <p class="m-0 p-0">
                ISCED level 7 -  Masters degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>


            <div class="table-row md:hidden">
              <div class="px-4 py-2 mt-0 bg-rose-100 block mt-2 md:table-cell md:w-3/12">Doctoral or equivalent level</div>
              <div class="px-4 py-2 bg-rose-100 block md:table-cell md:w-fit">
                 &ndash;
              </div>
              <div class="px-4 py-2 bg-rose-100 block md:table-cell md:w-fit" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px;">
                Doctorate
                <p class="m-0 p-0">
                  ISCED level 8 -  Doctoral degree<br/>or equivalent tertiary education level
                </p>
              </div>
            </div>

          <div class="hidden md:table-row bg-rose-100">
            <div class="px-4 py-2 block mt-2 md:table-cell md:w-3/12" style="border-bottom-left-radius: 6px;">Doctoral or equivalent level</div>
            <div class="px-4 py-2 block md:table-cell md:w-fit">
               &ndash;
            </div>
            <div class="px-4 py-2 block md:table-cell md:w-fit" style="border-bottom-right-radius: 6px;">
              Doctorate
              <p class="m-0 p-0">
                ISCED level 8 -  Doctoral degree<br/>or equivalent tertiary education level
              </p>
            </div>
          </div>

        </div>
      </div>

      <!-- Additional style guides -->
      <h2 class="mt-6 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        Check what is applicable your jurisdiction
      </h2>
      <p class="mt-2 mb-1">
      Check which guidelines and style guides apply in your jurisdiction. Guidelines for various levels of government will usually
      have some discussion about communication style.
      </p>
      <p class="my-2">
        <b>In Australia</b>
      </p>
      <p class="mb-0" style="margin-top: 0">
        At a federal level there is the <i><class="font-semibold">Australian Government Style Manual</class></i>
      </p>
      <p style="margin-top:0;">
        <span>
        <a class="external-link" href="https://www.stylemanual.gov.au/accessible-and-inclusive-content" target="_blank">
          https://www.stylemanual.gov.au/accessible-and-inclusive-content</a>.
        </span>
      </p>
      <p class="mt-2 mb-0">
        There are also guidelines at the state level, such as the Victorian Government’s
        <i><class="font-semibold">Accessibility guidelines for government communications</class></i>
      </p>
      <p style="margin-top:0;">
        <span>
        <a class="external-link" href="https://www.vic.gov.au/accessibility-guidelines-government-communications" target="_blank">
          https://www.vic.gov.au/accessibility-guidelines-government-communications</a>.
        </span>
      </p>
      <p class="mt-2 mb-0">
        Additionally there will also be guides that deal with specific subject matter. For instance, in Victoria the Victoria Legal Aid (VLA)
         <i><class="font-semibold">Our style guide</span></i> can be used when communicating legal information to the general public
      </p>
      <p class="mb-2" style="margin-top:0">
        <span>
        <a class="external-link" href="https://content.legalaid.vic.gov.au/sites/<br/>default/files/2024-06/vla-our-style-guide-2024.docx" target="_blank">
          <span class="md:hidden">https://content.legalaid.vic.gov.au/sites/</span>
          <span class="md:hidden">default/files/2024-06/vla-our-style-guide-2024.docx</span>
          <span class="hidden md:inline">https://content.legalaid.vic.gov.au/sites/default/files/2024-06/vla-our-style-guide-2024.docx</span>
          </a>.
        </span>
      </p>

      <!-- References -->
      <h2 class="mt-6 mb-2 font-sans text-lg text-brandSecondary font-semibold">
        References
      </h2>
      <p class="my-2">
        <b>WCAG</b>
      </p>
      <p class="mb-2">
        <a class="external-link" href="https://www.w3.org/TR/WCAG22/#reading-level" target="_blank">
        Web Content Accessibility Guidelines (WCAG) 2.2</a>
      </p>
      <p>
        <a class="external-link" href="https://www.w3.org/WAI/WCAG22/Understanding/reading-level.html" target="_blank">Understanding SC 3.1.5: Reading Level &lpar;Level AAA&rpar;</a>
      </p>
      <p class="my-2">
        <b>Australian (Federal) Government</b>
      </p>
      <p>
        <a class="external-link" href="https://www.stylemanual.gov.au/accessible-and-inclusive-content" target="_blank">
        Australian Government Style Manual</a>
      </p>
      <p class="my-2">
        <b>Other useful materials</b>
      </p>
      <p class="mb-4">
        <a class="external-link" href="https://www.tcsisupport.gov.au/sites/default/files/2019-12/isced-asced-concordance.pdf" target="_blank">
          International Standard Classification of Education 2011 (ISCED 2011) to Australian Standard Classification of Education (ASCED) Concordance</a>
      </p>
      <p>
        <a class="external-link" href="https://www.legalaid.vic.gov.au/making-community-legal-information" target="_blank">
          Making community legal information by Victoria Legal Aid (VLA)</a>
      </p>`,

}

export default translationsEn
