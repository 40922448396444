import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCookie } from "./Utilities"
import Tooltip from './Tooltip'

import './InterviewMainTextWithExtraInfo.css'

const preProcessForInfoIcons = (translate, text) => {
  const infoIcon = "<svg xmlns=\"http://www.w3.org/2000/svg\" id=\"clipboard-image\" class=\"inline text-brandSecondary h-6 w-6\" fill=\"none\" viewBox=\"0 0 24 24\" stroke-width=\"2\" stroke=\"currentColor\"> " +
    "<path strokeLinecap=\"round\" strokeLinejoin=\"round\" " +
    "d=\"m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z\" />" +
    "</svg>"

  let terms = []
  if (text !== null && typeof(text) !== 'undefined' && text.length > 0) {
    terms = text.match(/{(.*?)}/g)

    if (terms !== null && typeof(terms) !== 'undefined' && terms.length > 0) {
      terms = terms.map((t) => {return t.substring(1,t.length-1).trim()})
      // console.log("terms = ", terms)
    terms.forEach((t, i) => {
      text = text.replace("{" + t +"}",
      "<a id='tooltip-anchor-" + i + "' href='/#' class='termToDefine infoIcon' " +
      "aria-label=\"" + translate("more-info") + "\" " +
      "onClick='return false;'>" + infoIcon + "</a>")
    });
    }
  }

  return {text: text, terms: terms}
}

const InterviewMainTextWithExtraInfo = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  const preProcessedText = preProcessForInfoIcons(translate, props.interviewText)
  const mainText = preProcessedText.text
  const terms = preProcessedText.terms

  let tooltipClass = "LHTooltip_wrapper"

  try {
    // eslint-disable-next-line
    tooltipClass = (typeof props.extraClass === undefined)?"LTHTooltip_wrapper":"LHTooltip_wrapper " + props.extraClass
  } catch(e) { }

  let findEvaluation = mainText.search("<p hidden>blue</p>")
  if (findEvaluation >= 0) {
    tooltipClass = "LHTooltip_wrapper evaluation-medium"
  }

  findEvaluation = mainText.search("<p hidden>red</p>")
  if (findEvaluation >= 0) {
    tooltipClass = "LHTooltip_wrapper evaluation-high"
  }

  return(
    <>
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { <div dangerouslySetInnerHTML={{__html: props.inteviewTitle }} /> }
      </h1>
      <div className="interview-body mt-2 text-brandTextDark lg:mt-4">
        { <div dangerouslySetInnerHTML={{__html: mainText}} /> }
        {/* Need to wait for page to load so we are not rendering old terms */}
        { terms !== null && terms.length > 0 && props.isLoadedInApp === null &&
        <div className={tooltipClass}>
          <Tooltip terms={terms} definedTerms={props.interviewTerms} />
        </div>
        }
      </div>
    </>
  )
}

export default InterviewMainTextWithExtraInfo;
