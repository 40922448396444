import {React} from "react"
import TranslatedPage from "./TranslatedPage"

const Help = () => {

  return (
    <TranslatedPage heading='help-heading' mainText='help-text'/>
    )
}

export default Help
