// When the user has reached the last screen
import React, {useState} from "react"
import { useTranslation } from 'react-i18next'
import {getCookie} from "../Utilities"
import BackLink from "../BackLink"
import IconReload from "../icons/IconReload"
import IconExit from "../icons/IconExit"

import './Interview.css'

const LastPage = (props) => {
  const [clickedMainSiteButton, setClickedMainSiteButton] = useState(false)
  let lang = getCookie("DA-language")
  // Default language is English
  if ( typeof(lang) === "undefined" || lang !== "vi" ) {
    lang = "en"
    document.cookie = "DA-language=en; SameSite=Strict; Secure"
  }
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  let translate = i18n.getFixedT(lang)

  document.title = translate(props.interview.browser_title)

  return (
    <>
      <div id="interview-background"></div>
      <div id="interview" className="flex">
        <div className="m-auto">

          {/* Back Button */}
          {props.interview.event_list && props.interview.event_list.length > 0 && props.interview.event_list[0] !== process.env.REACT_APP_FIRST_SCREEN &&
          <div className="back-link hidden md:my-4 sm:mx-0 md:block min-w-screen">
            <BackLink goPrevQuestion = {props.goPrevQuestion} />
          </div>
          }

          {/* Width dependant on screen type*/}
          <div id="interview-outer-container"
          className="px-4 mx-0 md:p-6 md:mx-auto lg:p-10 md:rounded-md md:bg-white md:shadow-2xl md:w-screen max-w-screen-md">


            <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
              {translate("app-exit-message")}
            </h1>

            {/*
            <div className="interview-body mt-2 text-brandTextDark lg:mt-4">
              { <div dangerouslySetInnerHTML={{__html: translate("app-exit-thankyou")}} /> }
            </div>
            */}

            <div className="pt-4">
              <button
              className="mt-3 bg-brandPrimary hover:bg-brandPrimaryHover text-white px-6 py-4 rounded-ButtonRadius shadow-md lg:font-medium text-md font-medium mr-4"
              onClick={(e) => props.restartInterview(e)}
              >
                <IconReload className="inline align-text-bottom text-white mr-2" width='1.25rem' height='1.25rem' style={{ padding: '0 0 0.1rem 0', strokeWidth: '0.8px'}}/>
                {translate("reload-txt")}
              </button>

              { !clickedMainSiteButton &&
              <button
              className="mt-3 bg-brandPrimary hover:bg-brandPrimaryHover text-white px-6 py-4 rounded-ButtonRadius shadow-md lg:font-medium text-md font-medium mr-4"
              onClick={(e) => {
                e.preventDefault()
                setClickedMainSiteButton(true)
                window.location='https://legaltechhelper.com.au'
                }}
              >
                <IconExit className="inline align-text-bottom text-white mr-2" width='1.25rem' height='1.25rem' />
                {translate("web-site-txt")}
              </button>
              }

              { clickedMainSiteButton &&
              <button
              disabled
              className='mt-3 bg-gray-500 text-white py-4 rounded-ButtonRadius shadow-md lg:font-medium text-md font-medium mr-4'
              style = {{ "paddingLeft": "24px", "paddingRight": "18px"}}
              >
              <div className="inline-block bg-gradient-to-r from-gray-100 via-gray-400 bg-gray-500 bg-clip-text text-transparent">
                <IconExit className="inline align-text-bottom text-white mr-2" width='1.25rem' height='1.25rem' />
                {translate("web-site-txt")}
                <div className = "dot-pulse-wrapper-div inline">
                  <div className="dot-pulse inline-block"></div>
                </div>
              </div>
              </button>
              }

            </div>

          </div>

        </div>
      </div>
    </>
  )
}

export default LastPage;
