import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCookie } from "./Utilities"

const InterviewFields = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)

  const clearField = (event) => {
    document.getElementById(event.target.id).classList.remove("need-to-fill-in")
    // console.log("document.getElementById(event.target.id).classList = ", document.getElementById(event.target.id).classList)
  }

  return(
    <>
      <div className="col-span-full mt-4">
        <label htmlFor={props.variable_name}
          className={"block font-medium leading-6 text-brandCharcoal " + (props.required ? 'required' : '')}>{props.label}
        </label>
         <div className="mt-2">
         { props.datatype==="text" &&
           props.inputtype==="area" &&
           <textarea id={props.variable_name}
             name={props.variable_name}
             alt={translate("input-box")}
             placeholder={props.hint}
             onClick={(e) => {clearField(e)}}
             rows={props.rows}
             className="block w-full rounded-md border-0 p-2 text-brandTextDark shadow-sm ring-1 ring-inset ring-highlight-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-highlight-lighter sm:text-base sm:leading-6"></textarea>
         }

         { props.datatype==="text" &&
           props.inputtype!=="area" &&
           <input type="text" id={props.variable_name}
             name={props.variable_name}
             alt={translate("input-field")}
             placeholder={props.hint}
             onClick={(e) => {clearField(e)}}
             className="block w-full rounded-md border-0 p-2 text-brandTextDark shadow-sm ring-1 ring-inset ring-highlight-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-highlight-lighter sm:text-base sm:leading-6"
           />
         }

        { props.datatype==="password" &&
          <input type="password" id={props.variable_name}
            name={props.variable_name}
            alt={translate("password-field")}
            placeholder={props.hint}
            onClick={(e) => {clearField(e)}}
            className="block w-full rounded-md border-0 p-2 text-brandTextDark shadow-sm ring-1 ring-inset ring-highlight-light placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-highlight-lighter sm:text-base sm:leading-6"
          />
        }
         </div>
       </div>
    </>
  )
}

export default InterviewFields;
