import React, {useState, useEffect, useLayoutEffect} from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../../Utilities"
import InterviewMainTextWithExtraInfo from "../../InterviewMainTextWithExtraInfo"
import InterviewFields from "../../InterviewFields"
import HelpButton from "../../HelpButton"
import ApplicationButton from "../ApplicationButton"

import '../Interview.css'
import './InterviewARIEvaluation.css'

const InterviewARIEvaluation = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  const [scriptElement, setScriptElement] = useState(null)

  // Prepare input text fields for submission
  const multipleInputFields = (e, fields) => {
    let fieldsWithValues = []
    let fieldName, fieldValue
    for (let i = 0; i < fields.length; i++) {
      fieldName =  fields[i].variable_name
      fieldValue = document.getElementsByName(fields[i].variable_name)[0].value
      fieldsWithValues[i]={name: fieldName, value: fieldValue}
    }
    return fieldsWithValues
  }

  // Vaidate all input text fields
  const validateInputFields = (e, fields) => {
    let allFieldsValidated, thisFieldValidated
    let fieldValue
    for (let i = 0; i < fields.length; i++) {
      fieldValue = document.getElementsByName(fields[i].variable_name)[0].value
      if (fields[i].required) {
        thisFieldValidated = fieldValue.length > 0?true:false

        if (!thisFieldValidated) {
          document.getElementsByName(fields[i].variable_name)[0].classList.add("need-to-fill-in")
        }

        if (typeof(allFieldsValidated) === "undefined") {
          allFieldsValidated = thisFieldValidated
        } else {
          allFieldsValidated = allFieldsValidated && thisFieldValidated
        }
      }
    }
    return (allFieldsValidated)
  }

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  return (
    <>
      {/*
      <div className="col-span-full">
        <label className="block font-medium leading-6 text-brandCharcoal">Original text 02</label>
        {props.interview.subquestionText}
      </div>
      */}

      {/* Main Text of the Interview */}
      <InterviewMainTextWithExtraInfo
        isLoadedInApp={props.isLoadedInApp}
        inteviewTitle={props.interview.questionText}
        interviewText={props.interview.subquestionText}
        interviewTerms={props.interview.terms}
        extraClass="evaluations" />

      {/* Application buttons */}
      <div className="pt-4">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index) => (
          <ApplicationButton key={index}
            index = {index}
            id = {"app-button-" + index}
            name = {props.interview.fields[0].variable_name || interviewObj.variable_name}
            label =  {interviewObj.label}
            value = {interviewObj.value}
            buttonColor = {interviewObj.color}
            handleClick={props.handleClick}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {props.showButtonSpinner}
          />
        ))}

        {/* Help Button */}
        { props.interview.help && props.interview.help.label && props.interview.help.label.length > 0 &&
          < HelpButton
            isLoadedInApp = {props.isLoadedInApp}
            buttonKey={props.interview.fields[0].choices.length}
            help={props.interview.help}
            helpText={props.interview.helpText}
          />
        }
      </div>
    </>
  )
}

export default InterviewARIEvaluation;
