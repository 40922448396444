import React, {useState, useEffect, useLayoutEffect} from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../Utilities"
import ApplicationButton from "./ApplicationButton"

import './Interview.css'
import './RadioInterview.css'

const radioItemClick = (e) => {
  if (e.currentTarget.type === "radio" && e.currentTarget.checked) {
    document.getElementById("submit-button").value = e.currentTarget.value
  }
}

const getDefault = (field) => {
  for (let i = 0; i < field.choices.length; i++) {
    if (field.choices[i].default) {
      return field.choices[i].value
    }
    return ''
  }
}

const validateFields = (e) => {
  // Make sure at least one radio button option is chosen
  // console.log("(document.getElementById(\"submit-button\").value", document.getElementById("submit-button").value)
  return (document.getElementById("submit-button").value.length > 0)
}

const RadioInterview = (props) => {

  const targetName = `${props.interview.fields[0].variable_name || props.interview.fields[0].choices.variable_name}`
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  const [scriptElement, setScriptElement] = useState(null)

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])


  return (
    <>
      {/* Interview Header */}
      <h1 className="interview-h1 text-lg lg:text-2xl xl:text-3xl font-bold text-brandCharcoal">
        { props.interview.questionText }
      </h1>

      {/* Interview Text, really the help on "choice" items */}
      {props.interview.fields[0].choices.map(
      (choice, i) =>
        choice.help && choice.help.length > 0  &&
      <div className={`mt-4 text-brandTextDark lg:text-md ${i === 0 ? "lg:mt-4" : "lg:mt-2"}`} key = {i}>
        {choice.help}
      </div>
      ) }

      {/* Radio Buttons */}
      <div className="pt-2">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index) => (

            <label key = {index}
            id={"option-" + index}
            className="radio-panel flex bg-slate-100 border-1 border-slate-200 text-brandTextDark lg:text-md rounded-md hover:bg-slate-200 cursor-pointer shadow-sm lg:font-medium px-4 py-4 my-4 lg:py-5 lg:px-8 ">
              <input type="radio"
              defaultChecked={interviewObj.default}
              name={props.interview.fields[0].variable_name || interviewObj.variable_name}
              value={interviewObj.value} onClick={(e) => radioItemClick(e)}
              disabled={props.isLoadedInApp !== null}
              />
              { <span className="ml-3 radio-label" dangerouslySetInnerHTML={{ __html: interviewObj.label}} /> }
            </label>
        ))}

        {/* "Submit" Button */}
        <div>
          <ApplicationButton key={0}
            index = {0}
            id = "submit-button"
            name = {props.interview.fields[0].variable_name || props.interview.fields[0].choices.variable_name}
            label = {translate("continue-txt")}
            value = {getDefault(props.interview.fields[0])}
            buttonColor = ""
            handleClick={(e) => {
              if (validateFields(e)) {
                props.handleClick(e, {name: targetName, value: `${document.getElementById("submit-button").value}`})
              }
            }}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {true}
          />
        </div>
      </div>
    </>
  )
}

export default RadioInterview;
