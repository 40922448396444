import React, {useState, useEffect, useLayoutEffect} from "react"
import { useTranslation } from 'react-i18next'
import { getCookie } from "../Utilities"
import InterviewMainText from "../InterviewMainText"
import InterviewFields from "../InterviewFields"
import HelpButton from "../HelpButton"
import ApplicationButton from "./ApplicationButton"

import './Interview.css'

const RegularInterview = (props) => {
  const lang = getCookie("DA-language")
  // eslint-disable-next-line
  const { t, i18n } = useTranslation()
  const translate = i18n.getFixedT(lang)
  const [scriptElement, setScriptElement] = useState(null)

  // Prepare input text fields for submission
  const multipleInputFields = (e, fields) => {
    let fieldsWithValues = []
    let fieldName, fieldValue
    for (let i = 0; i < fields.length; i++) {
      fieldName =  fields[i].variable_name
      fieldValue = document.getElementsByName(fields[i].variable_name)[0].value
      fieldsWithValues[i]={name: fieldName, value: fieldValue}
    }
    return fieldsWithValues
  }

  // Vaidate all input text fields
  const validateInputFields = (e, fields) => {
    let allFieldsValidated, thisFieldValidated
    let fieldValue
    for (let i = 0; i < fields.length; i++) {
      fieldValue = document.getElementsByName(fields[i].variable_name)[0].value
      if (fields[i].required) {
        thisFieldValidated = fieldValue.length > 0?true:false

        if (!thisFieldValidated) {
          document.getElementsByName(fields[i].variable_name)[0].classList.add("need-to-fill-in")
        }

        if (typeof(allFieldsValidated) === "undefined") {
          allFieldsValidated = thisFieldValidated
        } else {
          allFieldsValidated = allFieldsValidated && thisFieldValidated
        }
      }
    }
    return (allFieldsValidated)
  }

  useEffect(() => {
    if (typeof(props.interview.script) !== "undefined") {
      const newElement = document.createElement('script')
      setScriptElement(newElement)
      const inlineCode = document.createTextNode(props.interview.script)
      newElement.appendChild(inlineCode)
      document.body.appendChild(newElement)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  useLayoutEffect(() => {
    if (scriptElement !== null) {
      document.body.removeChild(scriptElement)
      setScriptElement(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.interview.script])

  return (
    <>
      {/* Main Text of the Interview*/}
      <InterviewMainText
        isLoadedInApp={props.isLoadedInApp}
        inteviewTitle={props.interview.questionText}
        interviewText={props.interview.subquestionText}
        interviewTerms={props.interview.terms} />

      {/* Input fields */}
      { props.interview.fields &&
        props.interview.fields.length > 0 &&
        props.interview.fields[0].datatype &&
        (props.interview.fields[0].datatype === "text" ||
        props.interview.fields[0].datatype === "password") &&
        props.interview.fields.map((interviewField, index) => (
        <InterviewFields key={index}
          index={index}
          variable_name={interviewField.variable_name}
          active={interviewField.active}
          datatype={interviewField.datatype}
          inputtype={interviewField.inputtype}
          number={interviewField.number}
          label={interviewField.label}
          required={interviewField.required}
          hint={interviewField.hint}
          rows={interviewField.rows}
          validation_messages={interviewField.validation_messages}
          handleClick={props.handleClick}
          isLoadedInApp = {props.isLoadedInApp}
          showButtonSpinner = {props.showButtonSpinner}
        />
      ))}

      {/* Submit button for Input Fields */}
      { props.interview.fields &&
        props.interview.fields.length > 0 &&
        props.interview.fields[0].datatype &&
        (props.interview.fields[0].datatype === "text" ||
        props.interview.fields[0].datatype === "password") &&
          <div className="pt-4">
          <ApplicationButton key={0}
            index = {0}
            id = "multiple-fields-submit"
            name = {props.interview.fields[0].variable_name}
            label = {translate("continue-txt")}
            value = "multiple-fields-submit"
            buttonColor = ""
            handleClick={(e) => {
              if (validateInputFields(e, props.interview.fields)) {
                props.handleClick(e, multipleInputFields(e, props.interview.fields))
              }
            }}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {true}
          />
          </div>
      }

      {/* Application buttons */}
      <div className="pt-4">
        { props.interview.fields &&
          props.interview.fields.length > 0 &&
          props.interview.fields[0].choices &&
          props.interview.fields[0].choices.length > 0 &&
          props.interview.fields[0].choices.map((interviewObj, index) => (
          <ApplicationButton key={index}
            index = {index}
            id = {"app-button-" + index}
            name = {props.interview.fields[0].variable_name || interviewObj.variable_name}
            label =  {interviewObj.label}
            value = {interviewObj.value}
            buttonColor = {interviewObj.color}
            handleClick={props.handleClick}
            isLoadedInApp = {props.isLoadedInApp}
            showButtonSpinner = {props.showButtonSpinner}
          />
        ))}

        {/* Help Button */}
        { props.interview.help && props.interview.help.label && props.interview.help.label.length > 0 &&
          < HelpButton
            isLoadedInApp = {props.isLoadedInApp}
            buttonKey={props.interview.fields[0].choices.length}
            help={props.interview.help}
            helpText={props.interview.helpText}
          />
        }
      </div>
    </>
  )
}

export default RegularInterview;
